import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Holding ASB Accountable`}</h1>
    <p>{`This is an important one for me. I think it's crucial fo our ASB to be accountable throughout the year, and it should be the students motivating them to do it. Throughout the year, I've seen ASB love a lot of its effectiveness. I felt that as the year went by, there were less ideas being thrown out and a stronger push to have in-person events, which we all knew would not be possible. This is OK, though. This year was a transition year. From 100% normal in early 2020, to the crazy year we have right now. It's made us more digitally focused, expanded our horizons to the times and places we can do events, and more. `}</p>
    <p>{`I want next year to be amazing. The only way to do that is by having consistent, regular events and activities for Irvine High. Just having an event a month won't check out, it needs to be BIG. Doing crazy things like building structures in our quad like University High or redoing our school-wide lip dub, which happened my freshman year. This can only happen if we hold our ASB accountable. `}</p>
    <p>{`I think, in order to keep ASB accountable, the President or Vice President should write a weekly newsletter/report on what ASB did this week, and what they will do next week. It's important that students know what their elected officials are doing during ASB, because that time is precious and shouldn't be used for non-ASB purposes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      